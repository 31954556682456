import { createSlice } from '@reduxjs/toolkit'

export const scheduleEditorSlice = createSlice({
    name: 'scheduleEditor',
    initialState: {
        schedule: {},
        template: {
            name: '',
            workouts: []
        }
    },
    reducers: {
        setSchedule: (state, action) => {
            state.schedule = action.payload.schedule
        },
        setTemplate: (state, action) => {
            state.template = {
                ...state.template,
                ...action.payload,
            };
        },
        clearTemplate: (state, action) => {
            state.template = { 
                name: '',
                workouts: []
            }
        }
        
    }
})

export const { setSchedule, setTemplate, clearTemplate } = scheduleEditorSlice.actions

export default scheduleEditorSlice.reducer