import { createSlice } from '@reduxjs/toolkit'

export const questionnaireSlice = createSlice({
    name: 'questionnaire',
    initialState: {
        dates: [],
    },
    reducers: {
        setDates: (state, action) => { console.log({action})
            state.dates = action.payload
        }
    }
})

export const { setDates } = questionnaireSlice.actions

export default questionnaireSlice.reducer