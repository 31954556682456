import { createSlice } from '@reduxjs/toolkit'

export const headerSlice = createSlice({
    name: 'header',
    initialState: {
        pageTitle: "Home",  // current page title state management
        notifications: [],  // no of unread notifications
        newNotificationTitle: "", // message title used for push notifications
        newNotificationMessage : "",  // message of notification to be shown
        newNotificationStatus : 1,   // to check the notification type -  success/ error/ info
        settings: {},
        userRole : 'athlete',
        user : {},
        athleteId : 0,
        adminId : 0,
        coachId : 0,
        pitchesForCompletedWorkout: [],
        congratsModal: false
    },
    reducers: {
        setPageTitle: (state, action) => {
            state.pageTitle = action.payload.title
        },

        setUserRole: (state, action) => {
            state.userRole = action.payload.userRole
        },

        setSettings: (state, action) => {
            state.settings = action.payload.settings
        },

        setUser: (state, action) => {
            state.user = action.payload.user
        },

        setAthleteId: (state, action) => {
            state.athleteId = action.payload.athleteId
        },

        setCoachId: (state, action) => {
            state.coachId = action.payload.coachId
        },

        removeNotificationMessage: (state, action) => {
            state.newNotificationMessage = ""
            state.newNotificationTitle = ""
        },

        showNotification: (state, action) => {
            state.newNotificationMessage = action.payload.message
            state.newNotificationStatus = action.payload.status
            if(action.payload.title) state.newNotificationTitle = action.payload.title
        },

        setNotifications: (state, action) => {
            state.notifications = action.payload.notifications
        },

        setCongratsModal: (state, action) => {
            state.congratsModal = action.payload.congratsModal
        }
    }
})

export const { setPageTitle, removeNotificationMessage, showNotification, setSettings, setUser, setUserRole, setPitchesForCompletedWorkout, notifications, setNotifications, setCongratsModal } = headerSlice.actions

export default headerSlice.reducer