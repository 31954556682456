import React, { lazy, useEffect, useCallback } from 'react'
import './App.css'
import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-alpine.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { themeChange } from 'theme-change'
import checkAuth from './app/auth'
import initializeApp from './app/init'
import { getAuth, signInAnonymously } from "firebase/auth";
import { messaging } from './firebase'
import { getToken, onMessage } from 'firebase/messaging'
import { useDispatch, useSelector } from 'react-redux';
import { showNotification, setNotifications } from './features/common/headerSlice';
import axios from 'axios'


// Importing pages
const Layout = lazy(() => import('./containers/Layout'))
const Login = lazy(() => import('./pages/Login'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))
const ActivateAccount = lazy(() => import('./pages/ActivateAccount'))
const ResetPassword = lazy(() => import('./pages/ResetPassword'))


// Initializing different libraries
initializeApp()

// Check for login and initialize axios
const token = checkAuth()

function App() {

  const dispatch = useDispatch();
  const { user } = useSelector(state => state.header)

  const firebaseLoginAnonymously = () => {
    signInAnonymously(getAuth()).then((user) => {
    })
  }

  const messageActivation = useCallback(async () => {
    try {
      const token = await getToken(messaging, {
        vapidKey: 'BHrgo_riIdB9VQLmLG_fR4bqxTMUGE6UfLfZi90W4NrTSg2j4jF7sFLGA2gwEqBkY7yyrczE3KE8pwviwL5YDkM'
      });
      if (token !== user.firebaseToken) {
        await axios.post(`/api/users/${user.id}`, { firebaseToken: token });
      }
    } catch (error) {
      console.error('Error in messageActivation:', error);
    }
  }, [user.firebaseToken, user.id]);

  const requestPermission = useCallback(async () => {
    try {
      let permission;
      console.log('Requesting permission...');

      // safari only
      if ('safari' in window && 'pushNotification' in window.safari) {
        const permissionData = window.safari.pushNotification.permission('web.com.example.domain');
        permission = permissionData?.permission;

      } else if(window.Notification) {
        permission = await window.Notification.requestPermission()
      }

      window.removeEventListener("click", requestPermission);

      switch (permission) {
        case 'granted':
          firebaseLoginAnonymously();
          messageActivation()
          break;
        case 'denied':
          break;
        default:
          break;
      }
      
    } catch (error) {
      console.error('Request permission error', error)
    }
  }, [messageActivation])

  useEffect(() => {
    if(!user.id) return;

    window.addEventListener('click', requestPermission)
    onMessage(messaging, async(message) => {
      
      dispatch(showNotification({ message: message.notification.body, title: message.notification.title, status: 2 }));

      const response = await axios.get(`/api/notifications?user=${user.id}`)
      dispatch(setNotifications({ notifications: response.data }))

    })

    return () => window.removeEventListener("click", requestPermission);
  }, [dispatch, requestPermission, user.id])

  useEffect(() => {
    // 👆 daisy UI themes initialization
    themeChange(false)
  }, [])

  return (
    <>
      <Router>
        <Routes>
          <Route path="/reset-password/:userId/:resetToken" element={<ResetPassword />} />
          <Route path="/activate-account" element={<ActivateAccount />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          {/* Place new routes over this */}
          <Route path="/app/*" element={<Layout />} />
          <Route path="*" element={<Navigate to={token ? "/app/dashboard" : "/login"} replace />}/>
        </Routes>
      </Router>
    </>
  )
}

export default App
