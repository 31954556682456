import spinner from '../assets/spinner.png'

function SuspenseContent(){
    return(
        <div className="w-full h-screen text-gray-300 dark:text-gray-200 bg-base-100 flex justify-center items-center">
            <img className="rotate" src={spinner} alt="loading" />
        </div>
    )
}

export default SuspenseContent