// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyA5v2K8swyfFpO9458QGZBe3iFpFiOnO5k",
  authDomain: "connected-performance.firebaseapp.com",
  projectId: "connected-performance",
  storageBucket: "connected-performance.appspot.com",
  messagingSenderId: "325814456609",
  appId: "1:325814456609:web:1496e11ab0b92fb927ae60",
  measurementId: "G-3ESX5NTGRD"
};


const app = initializeApp(firebaseConfig);
getAnalytics(app);
const messaging = getMessaging(app);

export { messaging }

  