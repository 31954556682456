import { configureStore } from '@reduxjs/toolkit'
import headerSlice from '../features/common/headerSlice'
import modalSlice from '../features/common/modalSlice'
import rightDrawerSlice from '../features/common/rightDrawerSlice'
import tableSlice from '../features/table/tableSlice'
import scheduleEditorSlice from '../features/common/templateEditorSlice'
import questionnaireSlice from '../features/common/questionnaireSlice'
import utilSlice from '../features/common/utilSlice'

const combinedReducer = {
  header : headerSlice,
  table : tableSlice,
  rightDrawer : rightDrawerSlice,
  modal : modalSlice,
  questionnaire: questionnaireSlice,
  util: utilSlice,
  scheduleEditor: scheduleEditorSlice
}

export default configureStore({
    reducer: combinedReducer
})