import { createSlice } from '@reduxjs/toolkit'

export const utilSlice = createSlice({
    name: 'header',
    initialState: {
        update: false,  // Trigger to update a component instead of "useNavigate"
    },
    reducers: {
        setUpdate: (state, action) => {
            state.update = action.payload.update
        },
    }
})

export const { setUpdate } = utilSlice.actions

export default utilSlice.reducer