import axios from "axios"
import { setUser, setUserRole } from "../features/common/headerSlice"
import store from './store.js'


const checkAuth = () => {
  const TOKEN = localStorage.getItem("token")
  const PUBLIC_ROUTES = ["login", "activate-account", "forgot-password", "reset-password"]

  const isPublicPage = PUBLIC_ROUTES.some(r => {
    return window.location.href.includes(r)
  })

  if (!TOKEN && !isPublicPage) {
    window.location.href = '/login'
    return
  }

  if(process.env.REACT_APP_API_ENVIRONMENT === 'local') {
    axios.defaults.baseURL = 'http://localhost:4000'
  } else {
    axios.defaults.baseURL = `https://${process.env.REACT_APP_API_URL}`
  }

  axios.defaults.headers.common['Authorization'] = `Bearer ${TOKEN}`

  axios.interceptors.request.use(function (config) {
    
    const whiteList = [
      new RegExp(/^\/api\/chatGroupsMessage\/\d+$/),
      new RegExp(/^\/api\/notifications\?user=\d+$/),
      new RegExp(/^\/api\/chats\/\d+$/)
    ] 
    
    let showLoadingModal = true;
    for(const rgx of whiteList){
      if(rgx.test(config.url)) {
        showLoadingModal = false;
        break;
      }
    }
    if(showLoadingModal) {
      document.body.classList.add('loading-indicator');
    }

    return config
  }, function (error) {
    return Promise.reject(error);
  });

  axios.interceptors.response.use(function (response) {
    // UPDATE: Add this code to hide global loading indicator
    document.body.classList.remove('loading-indicator');
    return response;
  }, function (error) {
    document.body.classList.remove('loading-indicator');

    // TODO: redirect and show error
    return Promise.reject(error);
  });

  if(TOKEN !== "") {
    // validate token
    axios.get(`/api/me`)
      .then(res => {
        if(res.data.user?.status !== "approved") {
          // account is locked
          localStorage.setItem("token", "")
          window.location.href = '/login?account=locked'
          return 
        }
        store.dispatch(setUser({
          user: res.data.user
        }))
        store.dispatch(setUserRole({
          userRole: res.data.user.role
        }))
        return TOKEN
      })
      .catch(err => {
        console.error(err)
        localStorage.setItem("token", "")
        window.location.href = '/login'
        return
      })  
  }
  
}

export default checkAuth